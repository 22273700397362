import { Modal, Button, Carousel } from "react-bootstrap";
import ciro1 from "../../assets/images/ciro/ciro1.jpg";
import ciro2 from "../../assets/images/ciro/ciro2.jpg";
import ciro3 from "../../assets/images/ciro/ciro3.jpg";
import ciro4 from "../../assets/images/ciro/ciro4.jpg";
import ciro5 from "../../assets/images/ciro/ciro5.jpg";
import ciro6 from "../../assets/images/ciro/ciro6.jpg";
import ciro7 from "../../assets/images/ciro/ciro7.jpg";
import ciro8 from "../../assets/images/ciro/ciro8.jpg";
import ciro9 from "../../assets/images/ciro/ciro9.jpg";
import ciro10 from "../../assets/images/ciro/ciro10.jpg";
import ciro11 from "../../assets/images/ciro/ciro11.jpg";
import ciro12 from "../../assets/images/ciro/ciro12.jpg";
import ciro13 from "../../assets/images/ciro/ciro13.jpg";
import ciro14 from "../../assets/images/ciro/ciro14.jpg";
import ciro15 from "../../assets/images/ciro/ciro15.jpg";
import ciro16 from "../../assets/images/ciro/ciro16.jpg";
import ciro17 from "../../assets/images/ciro/ciro17.jpg";
import ciro18 from "../../assets/images/ciro/ciro18.jpg";

import cruzando1 from "../../assets/images/cruzando/cruzando1-min.jpg";
import cruzando2 from "../../assets/images/cruzando/cruzando2-min.jpg";
import cruzando3 from "../../assets/images/cruzando/cruzando3-min.jpg";
import cruzando4 from "../../assets/images/cruzando/cruzando4-min.jpg";

import fundamentalistas1 from "../../assets/images/fundamentalistas/fundamentalistas1.jpg";
import fundamentalistas2 from "../../assets/images/fundamentalistas/fundamentalistas2.jpg";
import fundamentalistas3 from "../../assets/images/fundamentalistas/fundamentalistas3.jpg";
import fundamentalistas4 from "../../assets/images/fundamentalistas/fundamentalistas4.jpg";

const ModalCustom = ({ title, body, show, handleClose }) => {
  return (() => {
    if (title == "ciro") {
      return (
        <Modal show={show} onHide={handleClose}>
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro1} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro3} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro2} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro4} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro5} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro6} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro7} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro8} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro9} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro10} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro11} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro12} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro13} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro14} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro15} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro16} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro17} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={ciro18} alt="First slide" />
            </Carousel.Item>
          </Carousel>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    if (title == "cruzando") {
      return (
        <Modal show={show} onHide={handleClose}>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={cruzando2}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={cruzando1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={cruzando4}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={cruzando3}
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    if (title == "fundamentalistas") {
        return (
          <Modal show={show} onHide={handleClose}>
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={fundamentalistas1}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={fundamentalistas4}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={fundamentalistas3}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={fundamentalistas2}
                  alt="First slide"
                />
              </Carousel.Item>
            </Carousel>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }
  })();
};

export default ModalCustom;
